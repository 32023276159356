import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import './index.css';
import '../../static/css/animate.css';
import Navbar from '../../components/navbar';
import { toggleLoader, kybSubmit, getKybStatus, setAddress } from "../../store/actions/Auth";

const KYB = (props) => {

    const [Name, setName] = useState("");
    const [businessEmail, setBusinessEmail] = useState("");
    const [ein, setEin] = useState("");
    const [website, setWebsite] = useState("");
    const [facebook, setFacebook] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [twitter, setTwitter] = useState("");
    const [businessDoc, setBusinessDoc] = useState("");
    const [socialSecurityNo, setSocialSecurityNo] = useState("");
    const [publicAddress, setPublicAddress] = useState("");
    const [kynValue, setKyb] = useState("");
    const [kynStatus, setKybStatus] = useState("");

    const handleOnWheel = (event) => {
        const { type } = event.target;
        if (type === 'number') {
            event.preventDefault();
        }
    }

    useEffect(() => {
        document.addEventListener('wheel', handleOnWheel, { passive: false });

        return () => {
            document.removeEventListener('wheel', handleOnWheel);
        };
    }, []);

    function handleEditChange(e) {
        if ([e.target.name] == "Name") {
            setName(e.target.value);
        } else if ([e.target.name] == "businessEmail") {
            setBusinessEmail(e.target.value);
        } else if ([e.target.name] == "ein") {
            setEin(e.target.value);
        } else if ([e.target.name] == "website") {
            setWebsite(e.target.value);
        } else if ([e.target.name] == "facebook") {
            setFacebook(e.target.value);
        } else if ([e.target.name] == "linkedin") {
            setLinkedin(e.target.value);
        } else if ([e.target.name] == "twitter") {
            setTwitter(e.target.value);
        } else if ([e.target.name] == "businessDoc") {

            setBusinessDoc(e.target.files[0]);
        } else if ([e.target.name] == "socialSecurityNo") {
            setSocialSecurityNo(e.target.value);
        }
    }

    useEffect(() => {
        if (props.kybSubmitted == true) {
            setName("");
            setBusinessEmail("");
            setEin("");
            setWebsite("");
            setFacebook("");
            setLinkedin("");
            setTwitter("");
            setBusinessDoc("");
            setSocialSecurityNo("");
        }
    }, [props.kybSubmitted]);

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        const publicAddress = (await web3.currentProvider.enable())[0];
        if (publicAddress) {
            props.setAddress(publicAddress);
            props.getKybStatus({publicAddress});
        }
    }

    function Submit(e) {
        try {

            e.preventDefault();
            
            let { publicAddress } = props;

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            if (props.kybStatus !== false) {
                if (props.kybStatus == "submitted") {
                    EventBus.publish("error", `Your KYN has already been submitted!`);
                    return;
                } else if (props.kybStatus == "approved") {
                    EventBus.publish("error", `Your KYN has already been approved!`);
                    return;
                }
            }

            if (Name == "") {
                EventBus.publish("error", `Please enter non profit title`);
                return;
            }

            if (businessEmail == "") {
                EventBus.publish("error", `Please enter business email`);
                return;
            }

            if (!businessEmail.toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )) {
                EventBus.publish("error", `Please enter business email`);
                return;
            }

            if (!Name.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter non profit title`);
                return;
            }

            if (!Name.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Non profit title must contain alphabets`);
                return;
            }

            if (socialSecurityNo == "") {
                EventBus.publish("error", `Please enter social security`);
                return;
            }

            if (!socialSecurityNo.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter social security`);
                return;
            }

            if (ein == "") {
                EventBus.publish("error", `Please enter non profit EIN`);
                return;
            }

            if (!ein.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter non profit EIN`);
                return;
            }

            if (businessDoc == "") {
                EventBus.publish("error", `Please upload business document`);
                return;
            }

            if (businessDoc == undefined) {
                EventBus.publish("error", `Please upload business document`);
                return;
            }

            props.toggleLoader({
                message: "KYN submission in Progress...",
                status: true,
            });

            const formData = new FormData();
            formData.append('document', businessDoc);
            formData.append('email', businessEmail);
            formData.append('publicAddress', publicAddress);
            formData.append('ein', ein);
            formData.append('title', Name);
            formData.append('ownerSocialNo', socialSecurityNo);
            formData.append('website', website);
            formData.append('facebook', facebook);
            formData.append('linkedin', linkedin);
            formData.append('twitter', twitter);
            formData.append('isGreenCross', true);

            props.kybSubmit(formData);

            props.toggleLoader({
                message: "KYN submitted...",
                status: false,
            });

        } catch (error) {
            EventBus.publish("error", error.message);
        }
    }

    // console.log("************ kybSubmitted :: ", props.kybSubmitted);
    // console.log("************ publicAddress :: ", props.publicAddress);

    return (
        <div className="wrapper">
            <Navbar />
            <div className="form-wrap mt-3">
                <div className="inner">
                    <h2>Register Your Business</h2>

                    <form onSubmit={Submit}>
                        <div className="form-group">
                            <input
                                type="text"
                                name="Name"
                                placeholder="Business Title"
                                onChange={handleEditChange}
                                value={Name}
                            />

                            <div className="info-wrap">
                                <img src="/images/infocircle.png" alt="" />

                                <div className="info">

                                    <p>Enter Your Business Title Here</p>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <input
                                type="text"
                                name="businessEmail"
                                placeholder="Business Email"
                                onChange={handleEditChange}
                                value={businessEmail}
                            />

                            <div className="info-wrap">
                                <img src="/images/infocircle.png" alt="" />

                                <div className="info">

                                    <p>Enter Your Business Email Here</p>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <input
                                type="text"
                                name="ein"
                                placeholder="Business EIN"
                                onChange={handleEditChange}
                                value={ein}
                            />

                            <div className="info-wrap">
                                <img src="/images/infocircle.png" alt="" />

                                <div className="info">

                                    <p>Enter Your Business EIN Number Here</p>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <input
                                type="text"
                                name="socialSecurityNo"
                                placeholder="Owner Social Security Number"
                                onChange={handleEditChange}
                                value={socialSecurityNo}
                            />

                            <div className="info-wrap">
                                <img src="/images/infocircle.png" alt="" />

                                <div className="info">

                                    <p>Enter Business Owner Social Security Number Here</p>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <input
                                type="url"
                                name="website"
                                placeholder="Website"
                                onChange={handleEditChange}
                                value={website}
                            />

                            <div className="info-wrap">
                                <img src="/images/infocircle.png" alt="" />

                                <div className="info">

                                    <p>Insert Your Website Link Here</p>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <input
                                type="url"
                                name="facebook"
                                placeholder="Facebook"
                                onChange={handleEditChange}
                                value={facebook}
                            />

                            <div className="info-wrap">
                                <img src="/images/infocircle.png" alt="" />

                                <div className="info">

                                    <p>Insert Your Facebook Link Here</p>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <input
                                type="url"
                                name="linkedin"
                                placeholder="Linkedin"
                                onChange={handleEditChange}
                                value={linkedin}
                            />

                            <div className="info-wrap">
                                <img src="/images/infocircle.png" alt="" />

                                <div className="info">

                                    <p>Insert Your Linkedin Link Here</p>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <input
                                type="url"
                                name="twitter"
                                placeholder="Twitter"
                                onChange={handleEditChange}
                                value={twitter}
                            />

                            <div className="info-wrap">
                                <img src="/images/infocircle.png" alt="" />

                                <div className="info">

                                    <p>Insert Your Twitter Link Here</p>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            {props.publicAddress !== "" ? (
                                <input
                                    type="text"
                                    placeholder={props.publicAddress}
                                    disabled
                                />
                            ) : (
                                <input
                                    type="text"
                                    placeholder="Please connect XDC Pay Wallet!"
                                    disabled
                                />
                            )}

                            <div className="info-wrap">
                                <img src="/images/infocircle.png" alt="" />

                                <div className="info">

                                    <p>Your Wallet Address Which Will Get Approval To Create A Lottery</p>
                                </div>
                            </div>
                        </div>

                        <div className="form-group filewrap">
                            <span>{businessDoc ? (businessDoc['name'] && businessDoc['name'].substring(0, 10) + '...') : "Upload PDF File*"}</span>
                            <div className="upload-btn"><img src="/images/arrowup.png" alt="Upload File Icon" />Upload PDF File</div>
                            <input
                                type="file"
                                name="businessDoc"
                                placeholder={businessDoc ? (businessDoc['name'] && businessDoc['name'].substring(0, 10) + '...') : "Upload PDF File*"}
                                accept=".pdf"
                                onChange={handleEditChange}
                            />

                            <div className="info-wrap">
                                <img src="/images/infocircle.png" alt="" />

                                <div className="info">

                                    <p>Upload Your Business Document Here</p>
                                </div>
                            </div>
                        </div>

                        <div>

                        </div>
                        <button className="common-btn w-100 py-3" type="submit">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div >
    );
}

const mapDispatchToProps = {
    kybSubmit,
    setAddress,
    toggleLoader,
    getKybStatus,
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, kybValue, kybStatus, kybSubmitted } = Auth;
    return { publicAddress, kybValue, kybStatus, kybSubmitted }
};

export default connect(mapStateToProps, mapDispatchToProps)(KYB);