import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import { setLottery, kybResponse, setKybStatus, setKyb, toggleLoader, setUriData } from '../actions/Auth';


function* getLottery() {
  const { error, response } = yield call(getCall, "/v1/getAllLotteries");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setLottery(response['data']['body']));
  }
};

function* kybSubmit({ payload }) {
  const { error, response } = yield call(postCall, { path: '/v1/kybSubmit', payload });
  if (error) {
    EventBus.publish('error', error['response']['data']['message'])
  } else if (response) {
    console.log("*** 1");
    yield put(kybResponse(true));
    EventBus.publish("success", response['data']['message'])
  }
};

function* getKybStatus({ payload }) {
  const { error, response } = yield call(postCall, { path: '/v3/getKybStatus', payload });
  if (error) {
    // EventBus.publish('error', error['response']['data']['message'])
  } else if (response) {
    // EventBus.publish("success", response['data']['message'])
    yield put(setKyb(response['data']['body']));
    yield put(setKybStatus(response['data']['body']['kybStatus']));
  }
};

export function* getUriData({ payload }) {
  const { error, response } = yield call(postCall, { path: "v1/getUriData", payload });
  if (response) {
    yield put(setUriData(response['data']['body']));
  }
};


function* actionWatcher() {
  yield takeEvery('KYB_SUBMIT', kybSubmit);
  yield takeEvery('GET_LOTTERY', getLottery);
  yield takeEvery('GET_URI_DATA', getUriData);
  yield takeEvery('GET_KYB_STATUS', getKybStatus);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
