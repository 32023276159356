import { PURGE } from "redux-persist";

let initialState = {
  publicAddress: localStorage.getItem("publicAddress"),
  setLoader: { message: 'Please Wait...', status: false },
  kybValue: [],
  kybStatus: false,
  kybSubmitted: false,
  lotteryList: [],
  uriData: [],
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {

    case 'LOGIN':
      localStorage.setItem("publicAddress", payload);
      return {
        ...state,
        publicAddress: payload,
      };

    case 'LOGOUT':
      localStorage.removeItem("publicAddress");
      return {
        ...state,
        publicAddress: '',
      };

    case 'SET_ADDRESS':
      localStorage.setItem("publicAddress", payload);
      return {
        ...state,
        publicAddress: payload,
      };

    case 'TOGGLE_LOADER':
      return {
        ...state,
        setLoader: payload,
      };

    case 'SET_LOTTERY':
      return {
        ...state,
        lotteryList: payload,
      };

    case "SET_URI_DATA":
      return {
        ...state,
        uriData: payload,
      };

    case 'KYB_RESPONSE':
      console.log("*** payload", payload);
      return {
        ...state,
        kybSubmitted: payload,
      };

    case 'SET_KYB_STATUS':
      return {
        ...state,
        kybStatus: payload,
      };

    case 'SET_KYB':
      return {
        ...state,
        kybValue: payload,
      };

    default:
      return state;
  }
};

export default Auth;