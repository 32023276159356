import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";


import './index.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader, getLottery, getUriData } from "../../store/actions/Auth";
import { NFTABI } from '../../store/contract/index';
import alternate from '../../static/images/alternate.jpg';


const Profile = (props) => {

    let [nftData, setNFTData] = useState([]);

    useEffect(() => {
        props.getLottery();
    }, []);

    useEffect(() => {
        if (props.publicAddress && web3) {
            if (props.uriData) updateData();
        }
    }, [props.uriData, props.publicAddress, web3]);

    async function updateData() {
        if (props && props.uriData.length > 0) {
            if (nftData.length == props.uriData.length) {
                let temp = nftData.map((item, idx) => {
                    return { ...item, ...props.uriData[idx] };
                })
                setNFTData(temp);
            }
        }
    }

    async function sortLottery() {
        try {
            let { lotteryList } = props;
            props.toggleLoader({
                message: "Loading Profile Data...",
                status: true,
            });
            let dataArray = [];
            let deployer = (await web3.currentProvider.enable())[0];
            let list = [];
            for (let item of lotteryList) {
                let NFTAddress = item['NFTAddress'];
                let NFTContract = new web3.eth.Contract(NFTABI, item['NFTAddress']);
                let owned = await NFTContract.methods.walletOfOwner(deployer).call({ from: deployer });
                if (owned && owned.length > 0) {
                    let name = await NFTContract.methods.name().call({ from: deployer });
                    let symbol = await NFTContract.methods.symbol().call({ from: deployer });
                    for (let item of owned) {
                        let data = await NFTContract.methods.tokenURI(parseInt(item)).call({ from: deployer });
                        if (data.split("/")[2] == 'gateway.pinata.cloud') {
                            const parts = data.split(`${item}.json`);
                            const newUrl = parts[0];
                            data = newUrl;
                        }
                        list.push(data);
                        dataArray.push({ NFTAddress, Name: name, Symbol: symbol, TokenID: item });
                    }
                }
            }
            props.getUriData({ list });
            setNFTData(dataArray);
            props.toggleLoader({
                message: "Loading Profile Data...",
                status: false,
            });
        } catch (error) {
            props.toggleLoader({
                message: "Loading Profile Data...",
                status: false,
            });
        }
    }

    useEffect(() => {
        if (props.publicAddress && props.lotteryList && props.lotteryList.length > 0) {
            sortLottery();
        }
    }, [props.publicAddress, props.lotteryList]);

    const handleImageError = (idx) => {
        // Set the failed image to the fallback image
        nftData[idx]['banner'] = alternate;
        setNFTData([...nftData]);
    };

    // console.log("************ publicAddress :: ", props.publicAddress);
    // console.log("************ lotteryList :: ", props.lotteryList);
    console.log("************ uriData :: ", props.uriData);
    console.log("************ nftData :: ", nftData);

    return (
        <div className="wrapper">
            <Navbar />

            <section className="profile">
                <div className="container">
                    <h1>Lottery NFTs</h1>

                    <div className="cards">
                        {
                            nftData && nftData.length > 0 &&
                            nftData.map((item, idx) => (
                                <div className="card">
                                    <img key={idx} src={item['image'] ? item['image'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />
                                    <h3>{item['Name']}</h3>
                                    <span>{item['Symbol']} #{item['TokenID']}</span>
                                    {/* <p>This is paragraph</p> */}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </div >
    );
}

const mapDispatchToProps = {
    toggleLoader,
    getLottery,
    getUriData
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, lotteryList, uriData } = Auth;
    return { publicAddress, lotteryList, uriData }
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);