/*========== APP ACTIONS ============= */

export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const logout = (data) => ({
  type: 'LOGOUT',
  payload: data,
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data,
});

export const toggleLoader = (data) => ({
  type: 'TOGGLE_LOADER',
  payload: data,
});


/*========== MARKETPLACE ACTIONS ============= */

export const getLottery = () => ({
  type: 'GET_LOTTERY'
});

export const setLottery = (data) => ({
  type: 'SET_LOTTERY',
  payload: data,
});

export const getUriData = (data) => ({
  type: 'GET_URI_DATA',
  payload: data,
});

export const setUriData = (data) => ({
  type: 'SET_URI_DATA',
  payload: data,
});

/*========== KYB ACTIONS ============= */

export const kybSubmit = (data) => ({
  type: 'KYB_SUBMIT',
  payload: data
});

export const kybResponse = (data) => (console.log("*** data",data),{
  type: 'KYB_RESPONSE',
  payload: data
});

export const getKybStatus = (data) => ({
  type: 'GET_KYB_STATUS',
  payload: data
});

export const setKybStatus = (data) => ({
  type: 'SET_KYB_STATUS',
  payload: data
});

export const setKyb = (data) => ({
  type: 'SET_KYB',
  payload: data
});