import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";


import './index.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader, getLottery, setAddress } from "../../store/actions/Auth";
import { NFTABI, LotteryABI, CIFITokenABI } from '../../store/contract/index';
import alternate from '../../static/images/alternate.jpg';


const LotteryMarketplace = (props) => {

    let [lotteryData, setLotteryData] = useState([]);

    useEffect(() => {
        getData();
        props.getLottery();
    }, []);

    async function getData() {
        const publicAddress = (await web3.currentProvider.enable())[0];
        if (publicAddress) {
            props.setAddress(publicAddress);
        }
    }

    async function sortLottery() {
        try {
            let { address, lotteryList } = props;
            props.toggleLoader({
                message: "Loading Lottery Data...",
                status: true,
            });
            let dataArray = [];
            for (let item of lotteryList) {
                let lotteryContract = new web3.eth.Contract(LotteryABI, item['lotteryAddress']);
                let NFTContract = new web3.eth.Contract(NFTABI, item['NFTAddress']);
                let tokenContract = new web3.eth.Contract(CIFITokenABI, item['paymentToken']);
                let nextCall = await lotteryContract.methods.nextCall().call({ from: address });
                let endTimestamp = await lotteryContract.methods.endTimestamp().call({ from: address });
                endTimestamp = parseInt(endTimestamp);
                let contractXDCBalance = await lotteryContract.methods.contractXDCBalance().call({ from: address });
                contractXDCBalance = web3.utils.fromWei(contractXDCBalance.toString(), "ether");
                let tokenSymbol = "XDC"
                if (item['paymentToken'] !== "0x0000000000000000000000000000000000000000") {
                    tokenSymbol = await tokenContract.methods.symbol().call({ from: address });
                }
                let now = parseInt(Date.now()/1000);
                let contractXRC20Balance = 0;
                if (item['paymentToken'] !== "0x0000000000000000000000000000000000000000") {
                    contractXRC20Balance = await lotteryContract.methods.contractXRC20Balance().call({ from: address });
                    contractXRC20Balance = web3.utils.fromWei(contractXRC20Balance.toString(), "ether");
                }
                let minted = await NFTContract.methods.totalSupply().call({ from: address });
                let createdAt = Date.parse(item['createdAt']) / 1000;
                dataArray.push({ ...item, nextCall, tokenSymbol, contractXDCBalance, contractXRC20Balance, minted, endTimestamp, now, createdAt });
            }
            setLotteryData(dataArray);
            props.toggleLoader({
                message: "Loading Lottery Data...",
                status: false,
            });
        } catch (error) {
            props.toggleLoader({
                message: "Loading Lottery Data...",
                status: false,
            });
        }
    }

    useEffect(() => {
        if (props.publicAddress && props.lotteryList && props.lotteryList.length > 0) {
            sortLottery();
        }
    }, [props.publicAddress, props.lotteryList]);

    async function mintNFT(item) {
        try {

            let remaining = item['tokenSupply'] - item['minted'];

            if (1 > remaining) {
                EventBus.publish('error', `Quantity exceeds remaining Tickets`);
                return;
            };

            /** Mint NFT **/

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            let tokenAmount = 0;

            let price = item['ticketPrice'];

            let sentValue = (parseInt(price) * 1);
            sentValue = web3.utils.toWei(sentValue.toString(), "ether");


            if (item['paymentToken'] !== "0x0000000000000000000000000000000000000000") {
                let contractPaymentToken = new web3.eth.Contract(CIFITokenABI, item['paymentToken']);
                let userBalance = await contractPaymentToken.methods.balanceOf(deployer).call();
                userBalance = web3.utils.fromWei(userBalance.toString(), "ether");


                if (parseInt(userBalance) < (parseInt(price) * parseInt(1))) return EventBus.publish("error", `Insufficient token amount`);
                props.toggleLoader({
                    message: "Approval in Progress...",
                    status: true,
                });

                tokenAmount = parseInt(price) * 1;
                tokenAmount = web3.utils.toWei(tokenAmount.toString(), "ether");

                console.log("*** tokenAmount", tokenAmount);

                await contractPaymentToken.methods.approve(item['NFTAddress'], tokenAmount.toString()).send({
                    from: deployer,
                });
                props.toggleLoader({
                    message: "Approval complete...",
                    status: true,
                });
                sentValue = 0;
            }

            props.toggleLoader({
                message: "Minting in Progress...",
                status: true,
            });

            let from = deployer;
            let output = deployer.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
            if (output == "xdc") {
                from = "0x" + deployer.substring(3);
            } else {
                from = deployer;
            }

            let contract = new web3.eth.Contract(NFTABI, item['NFTAddress']);

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: sentValue,
                    to: item['NFTAddress'],
                    gas: 5000000,
                    data: contract.methods
                        .mint(from, 1, tokenAmount.toString())
                        .encodeABI(),
                })
                .on('transactionHash', hash => {
                    console.log(`************** deploy contract hash = ${hash}`);
                })
                .on('receipt', async receipt => {
                    sortLottery();
                    props.toggleLoader({ status: false });
                    EventBus.publish("success", `NFT Minted Successfully!`);
                });


        } catch (e) {
            console.log('********Error = ', e);
            props.toggleLoader({ message: 'Minting not Completed...', status: false });
            EventBus.publish('error', `Unable to Mint NFT`);
        };
    };

    const handleImageError = (idx) => {
        // Set the failed image to the fallback image
        lotteryData[idx]['banner'] = alternate;
        setLotteryData([...lotteryData]);
    };

    console.log("************ publicAddress :: ", props.publicAddress);
    console.log("************ lotteryList :: ", props.lotteryList);
    console.log("************ lotteryData :: ", lotteryData);

    return (
        <div className="wrapper">
            <Navbar />

            <section class="casino-intro">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="content">
                                <h1>Lottery Marketplace: Your Gateway to Win Exciting Lotteries</h1>

                                <p>Welcome to the Lottery Marketplace where you can create, participate, and win lotteries. Our platform is the only place where you can experience the thrill of winning big Whether you're a creator with a vision or a player seeking excitement. Join us today to explore the possibilities and see where your luck takes you. </p>

                                <a class="common-btn" href="">Learn more</a>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            {/* <div id="canvas3d">
                                <Spline scene="https://prod.spline.design/DZtny3SZ5RfglyjT/scene.splinecode" />
                            </div> */}

                            <img src="/images/banner-img.png" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="lottery-list">
                <div className="container">
                    <div className="head">
                        <h2>Lottery list</h2>

                        <p>Explore a variety of lotteries created by our community or start your own for others to join and win big time.</p>
                    </div>

                    <div className="row">
                        {
                            lotteryData && lotteryData.length > 0 &&
                            lotteryData.map((item, idx) => (
                                <div className="col-lg-4 col-md-6">
                                    <div className="lottery-box">
                                        <img key={idx} src={item['banner'] ? item['banner'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />

                                        <div className="info">
                                            <div>
                                                <h3>{item['name']}</h3>
                                                <span>{item['minted']}/{item['tokenSupply']} minted</span>
                                            </div>

                                            <div>
                                                {
                                                    parseInt(item['now']) < parseInt(item['endTimestamp']) &&
                                                    <>
                                                        <button className="common-btn" onClick={() => mintNFT(item)}>Mint Ticket</button>
                                                        {item['paymentToken'] !== "0x0000000000000000000000000000000000000000" ?
                                                            <span>{item['ticketPrice']} {item['tokenSymbol']} </span>
                                                            :
                                                            <span>{item['ticketPrice']} XDC </span>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>

                                        <div className="detail">
                                            <a href={`/Leaderboard/${item['lotteryAddress']}`}>Details</a>
                                            {/* <a href={`/Leaderboard`}>Details</a> */}
                                        </div>

                                        {/* <div className="timer">
                                            <p id="day">{item['timer']['days']}<span>DAYS</span></p>
                                            <p id="hour">{item['timer']['hours']}<span>HRS</span></p>
                                            <p id="minute">{item['timer']['minutes']}<span>MIN</span></p>
                                            <p id="second">{item['timer']['seconds']}<span>SEC</span></p>
                                        </div> */}
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <img className="pattern" src="/images/pattern.png" />

                    {/* <div className="text-center mt-5">
                        <button className="common-btn">Load more</button>
                    </div> */}
                </div>
            </section>

            <section className="faq">
                <div className="container"> 
                    <h2>FAQs</h2>

                    <div id="accordion">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                What is the Lottery Marketplace?
                                <img src="/images/arrow.png" />
                                </button>
                            </h5>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body">
                            The Lottery Marketplace is a platform where users can create their own lotteries or participate in existing ones. It offers a seamless and exciting experience for both lottery creators and participants.
                            </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                How do I create my own lottery?
                                <img src="/images/arrow.png" />
                                </button>
                            </h5>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div class="card-body">
                            To create your own lottery, sign up for KYB and once it is approved, navigate to the "Create Lottery" section, and fill in the required details such as prizes, rules, and draw dates. Once submitted, your lottery will be listed for others to join.
                            </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                How do I purchase a lottery ticket?
                                <img src="/images/arrow.png" />
                                </button>
                            </h5>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                            <div class="card-body">
                            To purchase a lottery ticket, connect your wallet, browse the available lotteries, select the one you're interested in, and follow the prompts to buy a ticket.
                            </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header" id="headingFour">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                How are winners selected?
                                <img src="/images/arrow.png" />
                                </button>
                            </h5>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                            <div class="card-body">
                            Winners are selected through a random draw process, which is conducted transparently and fairly. The draw date and details are specified in each lottery's description.
                            </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header" id="headingFive">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Can I participate in multiple lotteries?
                                <img src="/images/arrow.png" />
                                </button>
                            </h5>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                            <div class="card-body">
                            Yes, you can participate in as many lotteries as you like. Simply browse the listings, choose your preferred lotteries, and purchase tickets for each one.
                            </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header" id="headingSix">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                What happens if I win?
                                <img src="/images/arrow.png" />
                                </button>
                            </h5>
                            </div>
                            <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                            <div class="card-body">
                            If you win, you will be notified via email and your account dashboard. Instructions for claiming your prize will be provided and winnings will be distributed according to the terms of the specific lottery.
                            </div>
                            </div>
                        </div>
                        </div>
                        <img className="pattern" src="/images/pattern.png" />
                    </div>
            </section>
            <Footer />
        </div >
    );
}

const mapDispatchToProps = {
    toggleLoader,
    getLottery,
    setAddress
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, lotteryList } = Auth;
    return { publicAddress, lotteryList }
};

export default connect(mapStateToProps, mapDispatchToProps)(LotteryMarketplace);