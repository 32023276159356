import React, { Component } from 'react';

import '../../app/LotteryMarketplace/index.css';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        return (
            <footer>
              <div className="container">
                <div className="inner">
                  <div className="left">
                    <a className="" href="/">
                      <img src="/images/logo.png" alt="" />
                    </a>

                    <p>The Future of Regenerative Finance.</p>
                  </div>
                  
                  <div className="links-wrapper">
                    <div className="wrap">
                      <span>Product</span>

                      <ul>
                        <li><a href="/" target="_blank">Documentation</a></li>
                        <li><a href="/" target="_blank">Resources</a></li>
                        <li><a href="/" target="_blank">Contact</a></li>
                        <li><a href="/" target="_blank">Buy XDC</a></li>
                      </ul>
                    </div>

                    <div className="wrap">
                      <span>App</span>

                      <ul>
                        <li><a href="#" target="_blank">Swap</a></li>
                        <li><a href="#" target="_blank">Stbility Pool</a></li>
                        <li><a href="#" target="_blank">Staking</a></li>
                        <li><a href="#" target="_blank">Microeconomy Tool</a></li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="copyright">
                  <p>Copyright ©2023 <span>Circularity Finance</span></p>

                  <a href="#">Terms & Conditions</a>
                </div>
              </div>
            </footer>
        );
    }
}

export default Footer;