import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";


import './index.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader } from "../../store/actions/Auth";
import { Token, TokenAddress, Lottery, LotteryAddress } from '../../store/contract/index';

const PayUpfront = (props) => {

    let [mintItems, setMintItems] = useState(10000);
    let [mintedItems, setMintedItems] = useState(0);
    let [balance, setBalance] = useState(0);
    let [txHash, setTxHash] = useState('');
    let [owned, setOwned] = useState(0);

    const [days, setDays] = React.useState(0);
    const [hours, setHours] = React.useState(0);
    const [minutes, setMinutes] = React.useState(0);
    const [seconds, setSeconds] = React.useState(0);

    const deadline = "July, 10, 2024";

    const getTime = () => {
        const time = Date.parse(deadline) - Date.now();    

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };

    React.useEffect(() => {
        const interval = setInterval(() => getTime(deadline), 1000);

        return () => clearInterval(interval);
    }, []);

    const handleOnWheel = (event) => {
        const { type } = event.target;
        if(type === 'number'){
          event.preventDefault();
        }
      }
      useEffect(() => {
        document.addEventListener('wheel', handleOnWheel, { passive: false });
    
        return () => {
          document.removeEventListener('wheel', handleOnWheel);
        };
      }, []);
      
    async function getContract() {
        try {
            let deployer = (await web3.currentProvider.enable())[0];
            let lBalance = await Lottery.methods.contractBalance().call({ from: deployer });
            lBalance = web3.utils.fromWei(lBalance, 'ether');
            lBalance = parseInt(lBalance);
            let minted = await Token.methods.totalSupply().call({ from: deployer });
            minted = parseInt(minted);
            let holdings = await Token.methods.balanceOf(deployer).call({ from: deployer });
            holdings = parseInt(holdings);
            let balanceWei = await web3.eth.getBalance(deployer);
            let balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            balanceEther = parseInt(balanceEther);
            console.log("************ balanceEther", balanceEther);
            setMintedItems(minted);
            setOwned(holdings);
            setBalance(balanceEther);
        } catch (e) {
            console.log("*** error :: ", e);
        }
    }

    useEffect(() => {
        if (props.publicAddress !== null && props.publicAddress !== undefined && props.publicAddress !== "") {
            getContract();
        }
    }, [props.publicAddress])

    async function copiedHash() {
        EventBus.publish("success", "Transaction Hash Copied");
    }

    async function mintNFT() {
        try {

            let remaining = mintItems - mintedItems;

            if (1 > remaining) {
                EventBus.publish('error', `Quantity exceeds remaining NFT's`);
                return;
            };

            console.log("******** balance", balance);

            if (balance < 0.1) {
                EventBus.publish('error', `Your have insufficient MATIC Balance`);
                return;
            };

            if (owned > 0) {
                EventBus.publish('info', `You have already minted you NFT!`);
                return;
            };

            /** Mint NFT **/

            let deployer = (await web3.currentProvider.enable())[0];

            // let sentValue = 0.1;
            let sentValue = web3.utils.toWei("0.1", 'ether');

            props.toggleLoader({
                message: "Mint in Progress ...",
                status: true,
            });

            await web3.eth.sendTransaction({
                from: deployer,
                value: sentValue,
                to: LotteryAddress,
                data: Lottery.methods.enter(1).encodeABI(),
            }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
                .on('receipt', async (receipt) => {
                    await web3.eth.sendTransaction({
                        from: deployer,
                        value: 0,
                        to: TokenAddress,
                        data: Token.methods.mint(deployer, 1).encodeABI(),
                    }).on('transactionHash', hash => {
                        console.log(`************** deploy contract hash = ${hash}`);
                        setTxHash(hash);
                    })
                        .on('receipt', async receipt => {
                            mintedItems = mintedItems + 1;
                            setMintedItems(mintedItems);
                            props.toggleLoader({ message: 'Minting Completed...', status: false });
                            EventBus.publish('success', `NFT minted successfully`);
                        })
                })
        } catch (e) {
            console.log('********Error = ', e);
            props.toggleLoader({ message: 'Minting not Completed...', status: false });
            EventBus.publish('error', `Unable to Mint NFT`);
        };
    };

    console.log("************ publicAddress :: ", props.publicAddress);

    return (
        <div className="wrapper">
            <Navbar />
            <div className="pay-upfront-head">
                <h2>Pay Upfront</h2>

                <p>Secure your place in Lottery Marketplace & the chance to win big by paying upfront.</p>

                <div className="life-time">
                    $199.99 <span>Lifetime</span>
                </div>
            </div>
            <div className="form-wrap mt-3">
                <div className="inner">
                    <h2>Enter card information</h2>
                    
                    <form >
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Card number"
                            />
                        </div>

                        <div className="form-group">
                        <input
                            type="text"
                            placeholder="Name on card"
                        />
                        </div>

                        <div className="row">
                            <div className="form-group col-8 pr-2">
                            <input
                                type="url"
                                placeholder="Expiry date"
                            />
                            </div> 

                            <div className="form-group col-4 pl-2">
                            <input
                                type="url"
                                placeholder="CVV"
                            />
                            </div>
                        </div>

                        <div className="form-group checkbox-wrap mb-4">
                            <input
                                type="checkbox"
                            />

                            <label>Acknowledge Amet minim mollit non deserunt ullamco est sit aliqua dolor <a href="#">term of services</a> do amet sint. <a href="#">Privacy policy</a> Velit officia consequat duis enim velit mollit.</label>
                        </div>

                        <button  className="common-btn w-100 py-3" type="submit">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div >
    );
}

const mapDispatchToProps = { toggleLoader };

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(PayUpfront);