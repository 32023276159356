import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component } from 'react';

import { web3 } from '../../store/web3';
import '../../app/LotteryMarketplace/index.css';
import { networkId, message } from '../../store/config';
import { login, logout, setAddress, getKybStatus } from '../../store/actions/Auth';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            address: '',
            token: '',
            errorText: ''
        };
    };

    componentWillReceiveProps({ publicAddress }) {
        this.setState({ address: publicAddress })
    }

    connectWallet = async () => {
        if (typeof window.ethereum === 'undefined') {
            EventBus.publish('error', `Please Install Metamask!!!`);
            return;
        }

        web3.eth.net.getId(async (err, netId) => {
            if (netId != networkId) {
                EventBus.publish('info', message);
                return;
            }
            let address = (await web3.currentProvider.enable())[0];
            this.props.setAddress(address);
            this.props.getKybStatus({ publicAddress: address });
            this.props.login(address);
            this.setState({ address });
        });
    };

    componentDidMount() {
        this.getData();
    }

    async getData() {
        const publicAddress = (await web3.currentProvider.enable())[0];
        if (publicAddress) {
            this.props.getKybStatus({ publicAddress });
        }
    }

    logout() {
        // Delete all items from local storage to logout the user
        localStorage.clear();
        this.props.logout('');
        this.setState({ token: '', address: '' });
    }

    render() {
        let { publicAddress, kybStatus } = this.props;
        console.log("*** kybStatus :: ", kybStatus);
        return (
            <header>
                <div class="container">
                    <nav class="navbar">
                        <a href="/" class="logo" >
                            <img src="/images/logo.png" alt="" />
                        </a>
                        {/* {
                            publicAddress == '' || publicAddress == null ?
                                <button onClick={this.connectWallet} className="common-btn" type="button">
                                    {publicAddress == '' || publicAddress == null
                                        ? 'Connect wallet'
                                        : publicAddress && publicAddress.substring(0, 5) + '.....' + publicAddress.substring(37, publicAddress.length)
                                    }
                                </button>
                                :
                                <button onClick={this.logout} className="common-btn" type="button">
                                    {publicAddress == '' || publicAddress == null
                                        ? 'Connect wallet'
                                        : publicAddress && publicAddress.substring(0, 5) + '.....' + publicAddress.substring(37, publicAddress.length)
                                    }
                                </button>
                        } */}
                        <ul>
                            <li>
                                <a href="/Profile">Profile</a>
                            </li>
                        </ul>

                        <div className="btn-wrap">
                            {
                                kybStatus !== false ?
                                    <>
                                        {
                                            kybStatus == "submitted" && <button className="common-btn" onClick={() => EventBus.publish('info', `Your KYN has already been submitted, please wait for admin approval`)}>Create Lottery</button>
                                        }
                                        {
                                            kybStatus == "approved" && <button className="common-btn" onClick={() => window.open("https://dev.useradmin.lottery.cifiapp.com")}>Create Lottery</button>
                                        }
                                    </>
                                    :
                                    <button className="common-btn" onClick={() => window.location.href = "/KYB"}>Create Lottery</button>
                            }
                            {publicAddress === '' || publicAddress === null
                                ?
                                <button className="common-btn white" onClick={() => this.connectWallet()}>
                                    {publicAddress == '' || publicAddress == null
                                        ? 'Connect wallet'
                                        : publicAddress && publicAddress.substring(0, 5) + '.....' + publicAddress.substring(37, publicAddress.length)
                                    }
                                </button>
                                :
                                <button className="common-btn white" onClick={() => this.logout()}>
                                    {publicAddress == '' || publicAddress == null
                                        ? 'Connect wallet'
                                        : publicAddress && publicAddress.substring(0, 5) + '.....' + publicAddress.substring(37, publicAddress.length)
                                    }
                                </button>
                            }
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}
const mapDispatchToProps = {
    login,
    logout,
    setAddress,
    getKybStatus
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, kybStatus } = Auth;
    return { publicAddress, kybStatus }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);