import moment from "moment";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";


import './index.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader, getLottery, setAddress } from "../../store/actions/Auth";
import { NFTABI, LotteryABI, CIFITokenABI } from '../../store/contract/index';
import alternate from '../../static/images/alternate.jpg';


const Rooms = (props) => {

    let { lotteryAddress } = useParams();

    let [lotteryData, setLotteryData] = useState({});

    let [days, setDays] = useState(0);
    let [hours, setHours] = useState(0);
    let [minutes, setMinutes] = useState(0);
    let [seconds, setSeconds] = useState(0);

    const getTime = (deadline) => {
        const time = (parseInt(deadline) * 1000) - Date.now();
        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };

    useEffect(() => {
        if (lotteryData && Object.keys(lotteryData).length > 0) {
            const interval = setInterval(() => getTime(lotteryData['nextCall']), 1000);
            return () => clearInterval(interval);
        }
    }, [lotteryData]);

    useEffect(() => {
        getData();
        props.getLottery();
    }, []);

    async function getData() {
        const publicAddress = (await web3.currentProvider.enable())[0];
        if (publicAddress) {
            props.setAddress(publicAddress);
        }
    }

    async function sortLottery() {
        try {
            let { address, lotteryList } = props;
            props.toggleLoader({
                message: "Loading Lottery Data...",
                status: true,
            });
            const filtered = await Promise.all(
                lotteryList.filter(item => item['lotteryAddress'].toLowerCase() == lotteryAddress.toLowerCase()).map(async (item) => {
                    return item;
                })
            );
            if (filtered.length > 0) {
                let item = filtered[0];
                let lotteryContract = new web3.eth.Contract(LotteryABI, item['lotteryAddress']);
                let NFTContract = new web3.eth.Contract(NFTABI, item['NFTAddress']);
                let tokenContract = new web3.eth.Contract(CIFITokenABI, item['paymentToken']);
                let nextCall = await lotteryContract.methods.nextCall().call({ from: address });
                let contractXDCBalance = await lotteryContract.methods.contractXDCBalance().call({ from: address });
                contractXDCBalance = web3.utils.fromWei(contractXDCBalance.toString(), "ether");
                let tokenSymbol = "XDC"
                if (item['paymentToken'] !== "0x0000000000000000000000000000000000000000") {
                    tokenSymbol = await tokenContract.methods.symbol().call({ from: address });
                }
                let contractXRC20Balance = 0;
                if (item['paymentToken'] !== "0x0000000000000000000000000000000000000000") {
                    contractXRC20Balance = await lotteryContract.methods.contractXRC20Balance().call({ from: address });
                    contractXRC20Balance = web3.utils.fromWei(contractXRC20Balance.toString(), "ether");
                }
                let minted = await NFTContract.methods.totalSupply().call({ from: address });
                let createdAt = Date.parse(item['createdAt']) / 1000;
                let timer = getTime(parseInt(nextCall));
                let winnerData = [];
                let ownerData = [];
                lotteryContract.events.LotteryWon({ fromBlock: 0 }, function (error, event) {
                    let data = { transactionHash: event['transactionHash'], ...event['returnValues'] }
                    winnerData.push(data);
                });
                lotteryContract.events.FoundersCut({ fromBlock: 0 }, function (error, event) {
                    let data = { transactionHash: event['transactionHash'], ...event['returnValues'] }
                    ownerData.push(data);
                });
                setLotteryData({ ...item, nextCall, tokenSymbol, contractXDCBalance, contractXRC20Balance, minted, createdAt, timer, winnerData, ownerData });
            }
            props.toggleLoader({
                message: "Loading Lottery Data...",
                status: false,
            });
        } catch (error) {
            props.toggleLoader({
                message: "Loading Lottery Data...",
                status: false,
            });
        }
    }

    useEffect(() => {
        if (props.publicAddress && props.lotteryList && props.lotteryList.length > 0) {
            sortLottery();
        }
    }, [props.publicAddress, props.lotteryList]);

    const handleImageError = () => {
        // Set the failed image to the fallback image
        lotteryData['banner'] = alternate;
        setLotteryData([...lotteryData]);
    };


    return (
        <div className="wrapper">
            <Navbar />

            <div className="lottery-details-wrap">
                <div className="container">
                    <div className="lottery-details">
                        <div className="inner">
                            {
                                lotteryData && Object.keys(lotteryData).length > 0 ?
                                    <img src={lotteryData['banner'] ? lotteryData['banner'] : alternate} onError={() => handleImageError()} alt="Alternate Image" />
                                    :
                                    <img src="/images/alternate.jpg" />
                            }

                            <div className="info">
                                <h3>Lottery details</h3>

                                {lotteryData && Object.keys(lotteryData).length > 0 &&
                                    <p>{lotteryData['name']}</p>
                                }

                                <div className="info-boxes-wrap">
                                    <div className="info-box">
                                        <span>Minted</span>

                                        {lotteryData && Object.keys(lotteryData).length > 0 &&
                                            <p>{lotteryData['minted']}/{lotteryData['tokenSupply']}</p>
                                        }
                                    </div>

                                    <div className="info-box">
                                        <span>Price per mint</span>

                                        {
                                            lotteryData && Object.keys(lotteryData).length > 0 &&
                                            <>
                                                {lotteryData['paymentToken'] !== "0x0000000000000000000000000000000000000000" ?
                                                    <p>{lotteryData['ticketPrice']} {lotteryData['tokenSymbol']} </p>
                                                    :
                                                    <p>{lotteryData['ticketPrice']} XDC </p>
                                                }
                                            </>
                                        }
                                    </div>

                                    <div className="info-box">
                                        <span>Date created</span>

                                        {
                                            lotteryData && Object.keys(lotteryData).length > 0 &&
                                            <p>{moment.utc(parseInt(lotteryData['createdAt']) * 1000).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                                        }
                                    </div>

                                    <div className="info-box">
                                        <span>Up coming draw date</span>

                                        {
                                            lotteryData && Object.keys(lotteryData).length > 0 &&
                                            <p>{moment.utc(parseInt(lotteryData['nextCall']) * 1000).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="timer">
                            <p id="day">{(days > 0) ? days : 0}<span>DAYS</span></p>
                            <p id="hour">{(hours > 0) ? hours : 0}<span>HRS</span></p>
                            <p id="minute">{(minutes > 0) ? minutes : 0}<span>MIN</span></p>
                            <p id="second">{(seconds > 0) ? seconds : 0}<span>SEC</span></p>
                        </div>
                    </div>
                </div>
            </div>

            <section class="leaderboard-wrap">
                <div class="container">
                    <div className="leaderboard">
                        <div className="inner">
                            <h2>Leaderboard</h2>

                            <div className="table-wrap">
                                <table responsive="md">
                                    <thead>
                                        <tr>
                                            {/* <th>Name</th> */}
                                            <th>Address</th>
                                            <th>Win amount</th>
                                            {/* <th>Draw date</th> */}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            lotteryData && Object.keys(lotteryData).length > 0 &&
                                            <>
                                                {
                                                    lotteryData['winnerData'] && lotteryData['winnerData'].length > 0 && lotteryData['winnerData'].map((item) => (
                                                        <tr>
                                                            <td>
                                                                <a href={`https://xdc.blocksscan.io/address/${item['to']}`} target="_blank">
                                                                    {item['to'] && item['to'].substring(0, 5) + '...' + item['to'].substring(37, item['to'].length)}
                                                                    <img className="address" src="/images/share-direct.png" />
                                                                </a>
                                                            </td>
                                                            <td>{web3.utils.fromWei(item['reward'].toString(), 'ether')}</td>
                                                        </tr>
                                                    ))
                                                }
                                                {
                                                    lotteryData['ownerData'] && lotteryData['ownerData'].length > 0 && lotteryData['ownerData'].map((item) => (
                                                        <tr>
                                                            <td>
                                                                <a href={`https://xdc.blocksscan.io/address/${item['to']}`} target="_blank">
                                                                    {item['to'] && item['to'].substring(0, 5) + '...' + item['to'].substring(37, item['to'].length)}
                                                                    <img className="address" src="/images/share-direct.png" />
                                                                </a>
                                                            </td>
                                                            <td>{web3.utils.fromWei(item['reward'].toString(), 'ether')}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <img className="pattern" src="/images/pattern.png" />

                            {/* <div className="text-center mt-5">
                                <button className="common-btn">Load more</button>
                            </div> */}
                        </div>
                    </div>

                </div>
            </section>

            <Footer />
        </div >
    );
}

const mapDispatchToProps = { toggleLoader, getLottery, setAddress };

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, lotteryList } = Auth;
    return { publicAddress, lotteryList }
};

export default connect(mapStateToProps, mapDispatchToProps)(Rooms);